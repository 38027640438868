



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
Component.registerHooks(['validations']);

@Component
export default class CreatePersonalInfo extends Vue {
  showOverlay = false;
  @Prop() personalInfo: any;
  options = [
          { text: 'Male', value: 'Male' },
          { text: 'Female', value: 'Female' },
        ]

  form = {
    gender: null,
    dateOfBirth: null
  }

  mounted() {
    this.form = {
      gender: this.personalInfo.gender,
      dateOfBirth: this.personalInfo.dateOfBirth
    };
  }

  @Watch('personalInfo')
  infoChanged(newVal: any) {
    this.resetForm();
    this.form = {
      gender: newVal.gender,
      dateOfBirth: newVal.dateOfBirth
    };
  }

  validations() {
    return {
      form: {
        gender: {
          required
        },
        dateOfBirth: {
          required
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      gender: null,
      dateOfBirth: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }

    this.showOverlay = true;

    if(this.personalInfo) {
      this.$store.dispatch('medInfo/editUser', this.form)
        .then(res => {
          this.$emit('newPersonalInfo');
          this.clearForm();
          this.showOverlay = false;
        },
        () => {
          this.showOverlay = false;
          this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to add personal info', variant: 'danger'});
        });
    }
    else {
      this.$store.dispatch('medInfo/createUser', this.form)
        .then(res => {
          this.$emit('newPersonalInfo');
          this.clearForm();
          this.showOverlay = false;
        },
        () => {
          this.showOverlay = false;
          this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to add personal info', variant: 'danger'});
        });
    }
    
  }

  clearForm() {
    this.resetForm();
  }
}
