



















































import { GetAllPhysician } from '@shared/models/physician';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators';
Component.registerHooks(['validations']);

@Component
export default class UpdateDiagnose extends Vue {
  @Prop() private diagnose!: any;
  options: { value: number; text: string } | null = null;
  physicians: any;
  showOverlay = false;
  form = {
    name: null,
    userPhysicianId: null
  }

  created() {
    this.$store.dispatch('medInfo/physician/getUserPhysicians')
      .then((res) =>  {
        this.physicians = res.data;
        this.options = res.data.map((item: GetAllPhysician) => {
          return { value: item.physician.id, text: item.physician.fullName };
        });
      })
      .catch((err) => console.log(err));     
  }

  mounted() {
    this.form = {
      name: this.diagnose.diagnose.name,
      userPhysicianId: this.diagnose.physician.id,
    };
  }

  @Watch('diagnose')
  couponChanged(newVal: any) {
    this.resetForm();
    this.form = {
      name: newVal.diagnose.name,
      userPhysicianId: newVal.physician.id,
    };
  }

  validations() {
    return {
      form: {
        name: {
          required
        },
        userPhysicianId: {
          required
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      name: null,
      userPhysicianId: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }
    const formToSend = {
      ...this.form, id: this.diagnose.id
    };
    formToSend.userPhysicianId = this.physicians.find((x: any) => x.physician.id === this.form.userPhysicianId).id;

    this.showOverlay = true;
    this.$store.dispatch('medInfo/diagnose/editUserDiagnose', formToSend)
      .then(res => {
        this.$emit('updateDiagnose', res.data);
        this.clearForm();
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Diagnose update', body: `Diagnose ${this.form.name} has been successfuly updated`, variant: 'primary'});
      },
      error => {
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to edit diagnose', variant: 'danger'});
      });
  }

  deleteDiagnose() {
    this.showOverlay = true;
    this.$store.dispatch('medInfo/diagnose/deleteUserDiagnose', this.diagnose.id)
      .then(() => {
        this.showOverlay = false;
        this.$emit('updateDiagnose', true);
        this.$store.dispatch('notify', {title: 'Diagnose delete', body: `Diagnose ${this.diagnose.diagnose.name} has been successfuly deleted`, variant: 'primary'});
      },
      () => {
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to delete diagnose', variant: 'danger'});
      });
  }

  clearForm() {
    // this.resetForm();
  }
}
