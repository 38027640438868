














































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators';
import { phoneFormat, phoneValidation } from '@shared/services/validators';
Component.registerHooks(['validations']);

@Component
export default class CreatePhysician extends Vue {
  showOverlay = false;
  resultsVisible = false;
  timer: number | undefined;
  searchedPhysicians = [];
  phoneFormat = phoneFormat;
  form = {
    fullName: null,
    specialty: null,
    phoneNumber: '',
    phoneFormat: ''
  }

  validations() {
    return {
      form: {
        fullName: {
          required
        },
        specialty: {
          required
        },
        phoneFormat: {
          required,
          phoneValidation
        }
      }
    };
  }

  @Watch('form.phoneFormat')
  formContactPhone(newVal: string) {
    this.form.phoneFormat = phoneFormat(newVal);
    this.form.phoneNumber = newVal.replace(/\D/g, '');
    if(this.form.phoneNumber.length > 4) {
      clearTimeout(this.timer);
      this.timer = window.setTimeout(() => {
        this.$store.dispatch('medInfo/physician/searchPhysicians', this.form.phoneNumber).then(res => {
          console.log(res.data);
          this.searchedPhysicians = res.data;
        });
      }, 1000);
    }
    else if(this.searchedPhysicians.length) {
      this.searchedPhysicians = [];
    }
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      fullName: null,
      specialty: null,
      phoneNumber: '',
      phoneFormat: ''
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  async onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }
    this.showOverlay = true;
    this.$store.dispatch('medInfo/physician/addUserPhysician', this.form)
      .then(res => {
        this.$emit('newPhysician');
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Physician added', body: `Physician ${this.form.fullName} added`, variant: 'success'});
        this.clearForm();
      },
      () => {
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to add physician', variant: 'danger'});
      });
  }

  clearForm() {
    this.resetForm();
  }

  selectPhysician(item: any) {
    this.form.phoneFormat = item.phoneNumber;
    this.form.fullName = item.fullName;
    this.form.specialty = item.specialty;
    this.resultsVisible = false;
  }
}
