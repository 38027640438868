

















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators';
import { phoneFormat, phoneValidation } from '@shared/services/validators';
Component.registerHooks(['validations']);

@Component
export default class UpdatePhysician extends Vue {
  @Prop() private physician!: any;
  $v: any;
  showOverlay = false;
  physicians: any;
  resultsVisible = false;
  timer: number | undefined;
  searchedPhysicians = [];
  phoneFormat = phoneFormat;
  form = {
    fullName: null,
    specialty: null,
    phoneNumber: '',
    phoneFormat: ''
  }

  created() {
    this.$store.dispatch('medInfo/physician/getUserPhysicians')
      .then((res) =>  {
        this.physicians = res.data;
      })
      .catch((err) => console.log(err));     
  }

  mounted() {
    this.form = {
      fullName: this.physician.physician.fullName,
      specialty: this.physician.physician.specialty,
      phoneNumber: this.physician.physician.phoneNumber,
      phoneFormat: this.physician.physician.phoneNumber
    };
  }

  @Watch('form.phoneFormat')
  formContactPhone(newVal: string) {
    this.form.phoneFormat = phoneFormat(newVal);
    this.form.phoneNumber = newVal.replace(/\D/g, '');
    if(this.form.phoneNumber.length > 4) {
      clearTimeout(this.timer);
      this.timer = window.setTimeout(() => {
        this.$store.dispatch('medInfo/physician/searchPhysicians', this.form.phoneNumber).then(res => {
          this.searchedPhysicians = res.data;
        });
      }, 1000);
    }
    else if(this.searchedPhysicians.length) {
      this.searchedPhysicians = [];
    }
  }

  @Watch('physician')
  couponChanged(newVal: any) {
    this.resetForm();
    this.form = {
      fullName: newVal.physician.fullName,
      specialty: newVal.physician.specialty,
      phoneNumber: newVal.physician.phoneNumber,
      phoneFormat: newVal.physician.phoneNumber
    };
  }

  validations() {
    return {
      form: {
        fullName: {
          required
        },
        specialty: {
          required
        },
        phoneFormat: {
          required,
          phoneValidation
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      fullName: null,
      specialty: null,
      phoneNumber: '',
      phoneFormat: ''
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  async onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }
    
    const existingPhysican = this.physician.physician;
    const form = this.form as any;
    if(existingPhysican.fullName.toLowerCase() === form.fullName.toLowerCase()
      && existingPhysican.specialty.toLowerCase() === form.specialty.toLowerCase()
      && existingPhysican.phoneNumber === form.phoneNumber) {
        this.$emit('updatePhysician', true);
        return;
      }

    const storeAction = 'editUserPhysician';
    this.showOverlay = true;
    this.$store.dispatch(`medInfo/physician/${storeAction}`, { ...this.form, id: this.physician.id })
      .then(res => {
        this.$emit('updatePhysician', true);
        this.clearForm();
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Physician update', body: `Physician ${this.form.fullName} has been successfuly updated`, variant: 'primary'});
      },
      error => {
        console.log(error);
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to edit physician', variant: 'danger'});
      });
  }

  deletePhysician() {
    this.showOverlay = true;
    this.$store.dispatch('medInfo/physician/deleteUserPhysician', this.physician.id)
      .then(() => {
        this.showOverlay = false;
        this.$emit('updatePhysician', true);
        this.$store.dispatch('notify', {title: 'Physician update', body: `Physician ${this.form.fullName} has been successfuly deleted`, variant: 'primary'});
      },
      () => {
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to delete physician', variant: 'danger'});
      });
  }

  selectPhysician(item: any) {
    this.form.phoneFormat = item.phoneNumber;
    this.form.fullName = item.fullName;
    this.form.specialty = item.specialty;
    this.resultsVisible = false;
  }

  clearForm() {
    // this.resetForm();
  }
}
