<template>
  <div class="l-my-info container">
    <header class="l-page__header">
      <router-link :to="`${routes.portal}`" exact>
        <button class="c-btn-icon">
          <b-icon icon="arrow-left-square-fill" />
        </button>
      </router-link>
      <h1 class="o-title h1">
        Edit My Information
      </h1>
    </header>
    <b-row>
      <div class="l-col">
        <MyLocations />
        <hr>
        <MyContacts />
      </div>
      <div class="l-col l-col--details">
        <Info @openVideo="openVideo" />
      </div>
    </b-row>
  </div>
</template>

<script>
import MyLocations from '@rs-911/components/MyLocations.vue';
import MyContacts from '@rs-911/components/MyContacts.vue';
import Info from '@rs-911/components/Info.vue';
import Logs from '@rs-911/components/Logs.vue';
import { Routes } from '@rs-911/router/routes';
import { Component, Vue } from 'vue-property-decorator';

@Component({
	components: {
		MyLocations,
		MyContacts,
		Info,
		Logs
	}
})
export default class MyInfo extends Vue {
  routes = Routes;
  showVideo = false;
  openVideo() {
    this.showVideo = !this.showVideo;
  }

}
</script>
