





















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators';
import { phoneFormat, phoneValidation } from '@shared/services/validators';
Component.registerHooks(['validations']);

@Component
export default class UpdateInfo extends Vue {
  @Prop() private info!: any;
  @Prop() personalInfo: any;
  showOverlay = false;
  options = [
          { text: 'Male', value: 'Male' },
          { text: 'Female', value: 'Female' },
        ]
  form = {
    firstname: null,
    lastname: null,
    phone: '',
    phoneFormat: '',
    gender: null,
    otherMedicalInfo: null,
    dateOfBirth: null
  }

  mounted() {
    this.form = {
      firstname: this.info.firstname,
      lastname: this.info.lastname,
      phone: this.info.phone,
      phoneFormat: this.info.phone,
      gender: this.personalInfo.gender,
      dateOfBirth: this.personalInfo.dateOfBirth,
      otherMedicalInfo: this.personalInfo.otherMedicalInfo
    };
  }

  @Watch('info')
  infoChanged(newVal: any) {
    this.form.firstname = newVal.firstname;
    this.form.lastname = newVal.lastname;
    this.form.phone = newVal.phone;
  }

  @Watch('personalInfo')
  personalInfoChanged(newVal: any) {
    this.form.gender = newVal.gender;
    this.form.dateOfBirth = newVal.dateOfBirth;
  }

  @Watch('form.phoneFormat')
  formContactPhone(newVal: string) {
    this.form.phoneFormat = phoneFormat(newVal);
    this.form.phone = newVal.replace(/\D/g, '');
  }

  validations() {
    return {
      form: {
        firstname: {
          required
        },
        lastname: {
          required
        },
        phoneFormat: {
          required,
          phoneValidation
        },
        gender: {
          required
        },
        dateOfBirth: {
          required
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      firstname: null,
      lastname: null,
      phone: '',
      phoneFormat: '',
      gender: null,
      dateOfBirth: null,
      otherMedicalInfo: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }

    const rs911part = {
      firstname: this.form.firstname,
      lastname: this.form.lastname,
      phone: this.form.phone,
    };

    const medinfoPart = {
      gender: this.form.gender,
      dateOfBirth: this.form.dateOfBirth,
      otherMedicalInfo: this.form.otherMedicalInfo
    };

    this.showOverlay = true;
    const promiseArr = [];
    promiseArr.push(this.$store.dispatch('rs911/updateUser', rs911part));

    if(this.personalInfo) {
      promiseArr.push(this.$store.dispatch('medInfo/editUser', medinfoPart));
    }
    else {
      promiseArr.push(this.$store.dispatch('medInfo/createUser', medinfoPart));
    }
    Promise.all(promiseArr).then(() => {
      this.$emit('updateUser');
      this.clearForm();
      this.showOverlay = false;
      this.$store.dispatch('notify', {title: '', body: 'User updated', variant: 'primary'});
    })
    .catch((err) => {
      this.showOverlay = false;
      this.$store.dispatch('notify', {title: 'Error', body: `${err?.data?.message}`, variant: 'danger'});
    });
  }

  clearForm() {
    // this.resetForm();
  }
}
