



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location }  from '@shared/models/location';
import UpdateLocation from '@rs-911/components/modal/UpdateLocation.vue';

@Component({
  components: {
    UpdateLocation
  }
})
export default class MyLocations extends Vue {
  locations: Location[] | null = null;
  selectedLocation: Location | null = null;
  isCreate = false;
  locationIconsOrder = ['home', 'building', 'industry', 'store', 'school', 'university', 'church', 'synagogue', 
    'mosque', 'hotel', 'clinic_medical', 'monument', 'book', 'shopping_cart', 'dumbbell', 'golf_ball', 
    'tennis_ball', 'basebalball', 'bowling_ball', 'footbalball', 'futbol', 'ice_skate', 'RV', 'anchor' ];

  created() {
    this.fetchAllLocations();
  }

  getLocation(slot: number) {
    return this.locations?.find(x => x.slot == slot);
  }
  
  showModalCreate() {
    this.$bvModal.show('modal-create-location');
  }

  newLocation() {
    this.fetchAllLocations();
    this.$store.dispatch('notify', {title: '', body: 'New location created', variant: 'success'});
  }

  updateLocation() {
    this.fetchAllLocations();
  }

  imageUrl(img: string) {
    if(this.locationIconsOrder.some(x => x === img)) {
      return require('@rs-911/assets/location-icons/' + img + '.svg');
    }
    return require('@rs-911/assets/location-icons/' + this.locationIconsOrder[0] + '.svg');
  }

  selectLocation(location: Location, slot = 0) {
    if(location) {
      this.isCreate = false;
      this.selectedLocation = location;
    }
    else {
      this.isCreate = true;
      this.selectedLocation = {
        slot: slot,
        slotname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        icon: 'home'
      };
    }
  }

  fetchAllLocations() {
    this.$store.dispatch('location/getAll')
      .then((res) =>  {
        this.locations = res.data;
      },
      (err) => console.log(err));
  }
}
