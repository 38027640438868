export enum AllergySeverity {
  Mild = 1,
  Moderate,
  Severe,
  Critical
}

export enum Test {
  Up = 1,
  Down = 2
}
