































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Contact }  from '@shared/models/contact';
import UpdateContact from '@rs-911/components/modal/UpdateContact.vue';

@Component({
  components: {
    UpdateContact
  }
})
export default class MyContacts extends Vue {
  @Prop() private msg!: string;
  contacts: Contact[] | null = null;
  isCreate = false;
  selectedContact: Contact | null = null;

  created() {
    this.fetchAllcontacts();
  }

  newContact() {
    this.fetchAllcontacts();
    this.$store.dispatch('notify', {title: '', body: 'New contact created', variant: 'success'});
  }

  updateContact() {
    this.fetchAllcontacts();
  }

  selectContact(contact: Contact) {
    if(contact) {
      this.isCreate = false;
      this.selectedContact = contact;
    }
    else {
      this.isCreate = true;
      this.selectedContact = {
        fullname: '',
        relationship: '',
        phone: '',
        infoaccess: true,
        favorite: false
      };
    }
  }

  fetchAllcontacts() {
    this.$store.dispatch('contact/getAll')
      .then((res) =>  this.contacts = res.data,
      (err) => console.log(err));
  }
}
