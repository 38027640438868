





































import { Component, Prop, Vue } from 'vue-property-decorator';
import CreateAllergy from '@rs-911/components/forms/CreateAllergy.vue';
import CreateDiagnose from '@rs-911/components/forms/CreateDiagnose.vue';
import CreateMedication from '@rs-911/components/forms/CreateMedication.vue';
import CreatePhysician from '@rs-911/components/forms/CreatePhysician.vue';
import CreatePersonalInfo from '@rs-911/components/forms/CreatePersonalInfo.vue';
import UpdateAllergy from '@rs-911/components/forms/UpdateAllergy.vue';
import UpdateInfo from '@rs-911/components/forms/UpdateInfo.vue';
import UpdateDiagnose from '@rs-911/components/forms/UpdateDiagnose.vue';
import UpdatePhysician from '@rs-911/components/forms/UpdatePhysician.vue';
import UpdateMedication from '@rs-911/components/forms/UpdateMedication.vue';
import InfoList from '@rs-911/components/InfoList.vue';
import { Allergy } from '@shared/models/allergy';
import { Diagnose } from '@shared/models/diagnose';
import { Physician } from '@shared/models/physician';

@Component({
  components: {
    InfoList,
    CreateAllergy,
    CreateDiagnose,
    CreateMedication,
    CreatePhysician,
    CreatePersonalInfo,
    UpdateAllergy,
    UpdateDiagnose,
    UpdatePhysician,
    UpdateMedication,
    UpdateInfo
  }
})
export default class Info extends Vue {
  locations: any = null;
  allergies: any = null;
  diagnoses: any = null;
  physicians: any = null;
  medications: any = null;
  userInfo: any = null;
  personalInfo: any = null;
  showIfTrue = this.resetShowIfTrue();
  selectedAllergy: Allergy | null = null;
  selectedDiagnose: Diagnose | null = null;
  selectedPhysician: Physician | null = null;
  selectedMedication: any | null = null;
  openVideo() {
    this.$emit('openVideo');
  }

  createItem(type: string) {
    this.showIfTrue = this.resetShowIfTrue();
    switch(type) {
      case 'personalInfo':
        this.showIfTrue.createPersonalInfo = true;
        break;
      case 'allergy':
        this.showIfTrue.createAllergy = true;
        break;
      case 'physician':
        this.showIfTrue.createPhysician = true;
        break;
      case 'diagnose':
        this.showIfTrue.createDiagnose = true;
        break;
      case 'medication':
        this.showIfTrue.createMedication = true;
        break;
    }
  }

  updateItem(x: {type: string; item: any}) {
    this.showIfTrue = this.resetShowIfTrue();
    switch(x.type) {
      case 'allergy':
        this.selectedAllergy = this.allergies.find((allergy: { id: number }) => allergy.id === x.item.id);
        this.showIfTrue.updateAllergy = true;
        break;
      case 'physician':
        this.selectedPhysician = this.physicians.find((phy: { id: number }) => phy.id === x.item.id);
        this.showIfTrue.updatePhysician = true;
        break;
      case 'diagnose':
        this.selectedDiagnose = this.diagnoses.find((diagnose: { id: number }) => diagnose.id === x.item.id);
        this.showIfTrue.updateDiagnose = true;
        break;
      case 'medication':
        this.selectedMedication = this.medications.find((med: { id: number }) => med.id === x.item.id);
        this.showIfTrue.updateMedication = true;
        break;
      case 'info':
        this.showIfTrue.updateInfo = true;
        break;
    }
  }

  resetShowIfTrue() {
    return {
      createAllergy: false,
      updateAllergy: false,
      createDiagnose: false,
      updateDiagnose: false,
      createPhysician: false,
      createPersonalInfo: false,
      updatePhysician: false,
      createMedication: false,
      updateMedication: false,
      updateInfo: false
    };
  }

  created() {
    this.fetchAllLocations();
    this.getAllAllergies();
    this.getAllDiagnoses();
    this.getAllPhysicians();
    this.getAllMedications();
    this.getUserInfo();
    // this.getUserPersonalInfo();
  }

  fetchAllLocations() {
    this.$store.dispatch('location/getAll')
      .then((res) =>  this.locations = res.data,
      (err) => console.log(err));
  }

  getAllAllergies(reset = false){
    // triggered when new allergy is created
    this.$store.dispatch('medInfo/allergy/getUserAllergies')
      .then((res) =>  this.allergies = res.data,
      (err) => console.log(err));
    if(reset) this.showIfTrue = this.resetShowIfTrue();
  }

  getAllDiagnoses(reset = false){
    // triggered when new diagnose is created
    this.$store.dispatch('medInfo/diagnose/getUserDiagnoses')
      .then((res) =>  this.diagnoses = res.data,
      (err) => console.log(err));
    if(reset) this.showIfTrue = this.resetShowIfTrue();
  }

  getAllPhysicians(reset = false){
    // triggered when new physician is created
    this.$store.dispatch('medInfo/physician/getUserPhysicians')
      .then((res) =>  this.physicians = res.data,
      (err) => console.log(err));
    if(reset) this.showIfTrue = this.resetShowIfTrue();
  }

  getAllMedications(reset = false){
    // triggered when new medication is created
    this.$store.dispatch('medInfo/medication/getUserMedications')
      .then((res) =>  this.medications = res.data,
      (err) => console.log(err));
    if(reset) this.showIfTrue = this.resetShowIfTrue();
  }

  getUserPersonalInfo() {
    this.$store.dispatch('medInfo/getUser')
      .then((res) =>  this.personalInfo = res.data,
      (err) => console.log(err));
  }

  getUserInfo() {
    this.$store.dispatch('medInfo/getUser')
      .then((res) =>  this.personalInfo = res.data,
      (err) => console.log(err));
    this.$store.dispatch('rs911/getUser').then(res => {
      this.userInfo = res.data;
    })
    .catch(err => {
      console.log(err);
    });

  }
}
