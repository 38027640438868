




















































import { Component, Vue } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { GetAllPhysician } from '@shared/models/physician';
Component.registerHooks(['validations']);

@Component
export default class CreateDiagnose extends Vue {
  options: { value: number; text: string } | null = null;
  showOverlay = false;
  form = {
    name: null,
    userPhysicianId: null
  }

  created() {
    this.$store.dispatch('medInfo/physician/getUserPhysicians')
      .then((res) =>  this.options = res.data.map((item: GetAllPhysician) => {
        return { value: item.id, text: item.physician.fullName };
      }),
      (err) => console.log(err));
  }

  validations() {
    return {
      form: {
        name: {
          required
        },
        userPhysicianId: {
          required
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      name: null,
      userPhysicianId: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }

    this.showOverlay = true;
    this.$store.dispatch('medInfo/diagnose/addUserDiagnose', this.form)
      .then(res => {
        this.$emit('newDiagnose');
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Diagnose added', body: `Diagnose ${this.form.name} added`, variant: 'success'});
        this.clearForm();
      },
      () => {
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to add diagnose', variant: 'danger'});
      });
  }

  clearForm() {
    this.resetForm();
  }
}
