


































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Contact } from '@shared/models/contact';
import { phoneFormat, phoneValidation } from '@shared/services/validators';
Component.registerHooks(['validations']);

@Component
export default class UpdateContact extends Vue {
  showOverlay = false;
  @Prop() private contact!: Contact;
  @Prop() private isCreate!: boolean;

  form = {
    fullname: null,
    relationship: null,
    phone: '',
    infoaccess: true
  }

  @Watch('form.phone')
  formContactPhone(newVal: string) {
    this.form.phone = phoneFormat(newVal);
  }

  @Watch('contact')
  contactChanged(newVal: Contact | any) {
    this.resetForm();
    this.form = {
      fullname: newVal.fullname,
      relationship: newVal.relationship,
      phone: newVal.phone,
      infoaccess: newVal.infoaccess
    };
  }
  
  validations() {
    return {
      form: {
        fullname: {
          required
        },
        relationship: {
          required
        },
        phone: {
          required,
          phoneValidation
        },
        infoaccess: {
          required
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      fullname: null,
      relationship: null,
      phone: '',
      infoaccess: true
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }

    const contact = {
      fullname: this.form.fullname,
      relationship: this.form.relationship,
      phone: this.form.phone.replace(/\D/g, ''),
      infoaccess: this.form.infoaccess,
      id: this.contact.id
    };
    const msg = this.isCreate ? 'Contact added.' : 'Contact updated.';
    const method = this.isCreate ? 'contact/create' : 'contact/update';
    const err = this.isCreate ? 'Error occured while trying to add contact' : 'Error occured while trying to update contact';
    this.showOverlay = true;
    this.$store.dispatch(method, contact)
      .then(res => {
        this.$emit('updateContact', res.data);
        this.clearForm();
        this.showOverlay = false;
        this.$bvModal.hide('modal-update-contact');
        this.$store.dispatch('notify', {title: '', body: msg, variant: 'info'});
      },
      error => {
        console.log(error);
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error', body: err, variant: 'danger'});
      });
  }

  deleteContact() {
    this.showOverlay = true;
    this.$store.dispatch('contact/delete', this.contact.id)
      .then(() => {
        this.showOverlay = false;
        this.$bvModal.hide('modal-update-contact');
        this.$emit('updateContact');
        this.$store.dispatch('notify', {title: 'Contact delete', body: `Contact ${this.contact.fullname} has been successfuly deleted`, variant: 'primary'});
      },
      () => {
        this.showOverlay = false;
        this.$bvModal.hide('modal-update-contact');
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to delete contact', variant: 'danger'});
      });
  }

  clearForm() {
    // empty
  }
}
