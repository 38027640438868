
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { phoneFormat } from '@shared/services/validators';

@Component
export default class Logs extends Vue {
  @Prop() private alertData!: any;
  phoneFormat = phoneFormat;
  contacts: any = null;

  mounted() {
    console.log('Logs', this.alertData);
    this.$store.dispatch('rs911/getContacts', this.alertData.code).then(response => {
      this.contacts = response.data.filter((x: any) => x.relationship != 'Caller');
    });
  }
}
