





























































import { AllergySeverity } from '@shared/models/enums';
import { Component, Vue } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
Component.registerHooks(['validations']);

@Component
export default class CreateAllergy extends Vue {
  showOverlay = false;
  searchString = '';
  list: Array<string> = [];
  timer: number | undefined;
  form = {
    name: null,
    allergySeverity: '1',
    epiPenRequired: false
  }

  get getSeverity() {
    return AllergySeverity[parseInt(this.form.allergySeverity)];
  }
  validations() {
    return {
      form: {
        name: {
          required
        },
        allergySeverity: {
          required
        },
        epiPenRequired: {
          required
        }
      }
    };
  }

  validateState(name: string|number) {
    if(name === 'name' && this.$v.form[name]?.$model?.length > 2 && this.searchString !== this.$v.form[name]?.$model) {
      this.searchString = this.$v.form[name]?.$model;
      clearTimeout(this.timer);
      this.timer = window.setTimeout(() => {
        this.$store.dispatch('medInfo/allergy/searchAllergies', this.$v.form[name]?.$model).then(res => {
          this.list = res.data;
        });
      }, 1500);
    }
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }



  resetForm() {
    this.form = {
      name: null,
      allergySeverity: '1',
      epiPenRequired: false 
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }

    let allergy: any;
    this.showOverlay = true;
    this.$store.dispatch('medInfo/allergy/searchAllergies', this.form.name).then(res => {
      const exists = res.data.find((x: { name: string }) => x.name === this.form.name);
      if(exists) {
        allergy = {
          id: exists.id,
          severity: parseInt(this.form.allergySeverity || ''),
          epiPenRequired: this.form.epiPenRequired
        };
      }
      else {
        allergy = {
          name: this.form.name,
          severity: parseInt(this.form.allergySeverity || ''),
          epiPenRequired: this.form.epiPenRequired
        };
      }
      this.$store.dispatch('medInfo/allergy/createUserAllergy', allergy)
        .then(() => {
          this.$emit('newAllergy');
          this.showOverlay = false;
          this.$store.dispatch('notify', {title: 'Allergy added', body: `Allergy ${this.form.name} added`, variant: 'success'});
          this.clearForm();
        },
        () => {
          this.showOverlay = false;
          this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to create allergy', variant: 'danger'});
        });
    });
  }

  clearForm() {
    this.resetForm();
  }
}
