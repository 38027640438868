






































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Location } from '@shared/models/location';
Component.registerHooks(['validations']);

@Component
export default class UpdateLocation extends Vue {
  showOverlay = false;
  @Prop() private location!: Location;
  @Prop() isCreate!: boolean;
	
  locationIconsOrder = ['home', 'building', 'industry', 'store', 'school', 'university', 'church', 'synagogue', 
    'mosque', 'hotel', 'clinic_medical', 'monument', 'book', 'shopping_cart', 'dumbbell', 'golf_ball', 
    'tennis_ball', 'basebalball', 'bowling_ball', 'footbalball', 'futbol', 'ice_skate', 'RV', 'anchor' ];

  imageUrl(img: string) {
    if(img) {
      return require('@rs-911/assets/location-icons/' + img + '.svg');
    }

  }

  form = {
    slotname: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    icon: ''
  }

  @Watch('location')
  locationChanged(newVal: Location | any) {
    this.resetForm();
    this.form = {
      slotname: newVal.slotname,
      address1: newVal.address1,
      address2: newVal.address2,
      city: newVal.city,
      state: newVal.state,
      zip: newVal.zip,
      icon: newVal.icon ? newVal.icon : 'home'
    };
  }
  
  validations() {
    return {
      form: {
        slotname: {
          required
        },
        address1: {
          required
        },
        address2: {
          required
        },
        city: {
          required
        },
        state: {
          required
        },
        zip: {
          required
        },
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  changeIcon(newIcon: string) {
    this.form.icon = newIcon;
  }

  resetForm() {
    this.form = {
      slotname: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,  
      icon: 'home'
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }

    const newLocation = {
      slot: this.location.slot,
      slotname: this.form.slotname,
      address1: this.form.address1,
      address2: this.form.address2,
      city: this.form.city,
      state: this.form.state,
      zip: this.form.zip,
      icon: this.form.icon
    };
    const msg = this.isCreate ? 'Location added' : 'Location updated';
    const msgVariant = this.isCreate ? 'success' : 'info';
    const method = this.isCreate ? 'location/create' : 'location/update';
    const err = this.isCreate ? 'Error occured while trying to add location' : 'Error occured while trying to update location';
    this.showOverlay = true;
    this.$store.dispatch(method, newLocation)
      .then(res => {
        this.$emit('updateLocation', res.data);
        this.clearForm();
        this.showOverlay = false;
        this.$bvModal.hide('modal-update-location');
        this.$store.dispatch('notify', {title: '', body: msg, variant: msgVariant});
      },
      error => {
        console.log(error);
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error', body: err, variant: 'danger'});
      });
  }

  deleteLocation() {
    this.showOverlay = true;
    this.$store.dispatch('location/delete', this.location.slot)
      .then(() => {
        this.showOverlay = false;
        this.$bvModal.hide('modal-update-location');
        this.$emit('updateLocation');
        this.$store.dispatch('notify', {title: 'Location delete', body: `Location ${this.location.slot} has been successfuly deleted`, variant: 'primary'});
      },
      () => {
        this.showOverlay = false;
        this.$bvModal.hide('modal-update-location');
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to delete location', variant: 'danger'});
      });
  }

  clearForm() {
    // empty
  }
}
