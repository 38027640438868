









































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InfoList extends Vue {
  @Prop() private items: any;
  @Prop() private title!: string;
  @Prop() private type!: string;
	@Prop() private path!: string;
	@Prop() selected: any;

  getName(item: any) {
    if(item.allergy) return item.allergy.name;
    if(item.medication) return item.medication.name;
    if(item.diagnose) return item.diagnose.name;
    if(item.physician) return item.physician.fullName;
  }

  createItem(event: any) {
    event.stopPropagation();
    this.$emit('createItem', this.type);
  }

  updateItem(item: any) {
    this.$emit('updateItem', { type: this.type, item: item });
  }
}
