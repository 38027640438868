


























































import { AllergySeverity } from '@shared/models/enums';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators';
import { Allergy } from '@shared/models/allergy';
Component.registerHooks(['validations']);

@Component
export default class UpdateAllergy extends Vue {
  @Prop() private allergy!: Allergy;
  showOverlay = false;
  form: any = {
    allergyName: null,
    allergySeverity: '1',
    epiPenRequired: false
  }

  mounted() {
    this.form = {
      allergyName: this.allergy.allergy.name,
      allergySeverity: this.allergy.severity,
      epiPenRequired: this.allergy.epiPenRequired
    };
  }

  @Watch('allergy')
  allergyChanged(newVal: Allergy) {
    this.resetForm();
    this.form = {
      allergyName: newVal.allergy.name,
      allergySeverity: newVal.severity,
      epiPenRequired: newVal.epiPenRequired
    };
  }

  get getSeverity() {
    return AllergySeverity[parseInt(this.form.allergySeverity)];
  }
  validations() {
    return {
      form: {
        allergyName: {
          required
        },
        allergySeverity: {
          required
        },
        epiPenRequired: {
          required
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      allergyName: null,
      allergySeverity: '1',
      epiPenRequired: false 
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }

    const newAllergy = {
      name: this.form.allergyName,
      severity: parseInt(this.form.allergySeverity || ''),
      epiPenRequired: this.form.epiPenRequired,
      id: this.allergy.id
    };
    this.showOverlay = true;
    this.$store.dispatch('medInfo/allergy/editUserAllergy', newAllergy)
      .then(res => {
        this.$emit('updateAllergy');
        this.clearForm();
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Allergy update', body: `Allergy ${this.allergy.allergy.name} has been successfuly updated`, variant: 'primary'});
      },
      error => {
        console.log(error);
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to edit allergy', variant: 'danger'});
      });
  }

  deleteAllergy() {
    this.showOverlay = true;
    this.$store.dispatch('medInfo/allergy/deleteUserAllergy', this.allergy.id)
      .then(() => {
        this.showOverlay = false;
        this.$emit('updateAllergy', true);
        this.$store.dispatch('notify', {title: 'Allergy update', body: `Allergy ${this.allergy.allergy.name} has been successfuly deleted`, variant: 'primary'});
      },
      () => {
        this.showOverlay = false;
        this.$store.dispatch('notify', {title: 'Error', body: 'Error occured while trying to delete allergy', variant: 'danger'});
      });
  }

  clearForm() {
    // this.resetForm();
  }
}
